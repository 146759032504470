import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const TermsPage = () => {
  const Data = useStaticQuery(graphql`
    query {
      contentfulTermsConditions{
     title
        content {
          json
        }
  }
    }
  `)

  return (
    <Layout>
      <SEO title="Terms & Conditions" />
      <Container className="mt-5">
        <h1>{Data.contentfulTermsConditions.title}</h1>
        {documentToReactComponents(Data.contentfulTermsConditions.content.json)}
      </Container>
    </Layout>
  )
}

export default TermsPage
